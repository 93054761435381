@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Roboto+Slab:wght@100;300;400;500;600;700&display=swap');

$h1-font-size: 1rem * 2.2;
$h2-font-size: 1rem * 1.8;
$h3-font-size: 1rem * 1.6;
$h4-font-size: 1rem * 1.3;

$font-size-base: .9375rem;
$font-family-serif: "Roboto Slab";
$font-family-sans-serif: "Open Sans", sans-serif;

$headings-font-weight: 400;

$breadcrumb-divider: quote(">");

$body-bg: #dadee838;

.form-text {
  @extend .text-muted;
  font-size: small;
}

h1, h2 {
  font-family: $font-family-serif;
}
