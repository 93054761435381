@import "bootstrap-overrides";
@import "~bootswatch/dist/flatly/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/flatly/bootswatch";
@import "@fortawesome/fontawesome-svg-core/styles";
@import "leaflet/dist/leaflet";
@import "react-loading-skeleton/dist/skeleton";
@import "extension-overrides";

body {
  overflow-x: hidden;
}

.application {
  margin-top: 70px;
  min-height: calc(100vh - 70px);
  overflow-x: hidden;

  @include media-breakpoint-down(md) {
    min-height: calc(100vh - 85px);
  }

}

.loud-header {
  text-shadow: 0 0.04em 0.04em #1c1d1d;
  font-size: 3.5rem;
  line-height: 5rem;
}

.text-gold {
  color: #ffd700 !important;
}
