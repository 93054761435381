.leaflet-control-attribution {
  display: none;
}

.clamp-lines__button {
  @extend .btn;
  @extend .btn-link;
  @extend .p-0;
}

.react-tel-input {
  font-family: $font-family-sans-serif !important;
  font-size: inherit !important;

  .form-control {
    background: $input-bg !important;
    color: $input-color !important;
    width: 100% !important;
    border:  $input-border-width solid $input-border-color !important;
    border-radius: $input-border-radius !important;
    outline: $input-font-size !important;
    transition: $input-transition !important;
    padding: 0.375rem 0.75rem 0.375rem 3.25rem !important;

    &:focus {
      background-color: $input-focus-bg !important;
      border-color: $input-focus-border-color !important;
      outline: 0 !important;
      box-shadow: $input-focus-box-shadow !important;
    }
  }
}
